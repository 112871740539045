import React from 'react';

function GuideForContact(props) {
    return (
        <div>
            <div id="content">
                <div className="container-fluid py-5 bg-body-tertiary">
                    <div className="row py-5">
                        <div className="col-sm-11 ms-2 ms-md-5">
                            <h2 className="fs-1 fw-bold ">Contact Us</h2>
                            <p>
                                <span className="text-accent-color ">Auckland Podiatry Service</span>
                            </p>
                            <p className="baseline "></p>
                        </div>

                    </div>
                </div>
            </div>

        </div>
    );
}

export default GuideForContact;