import React from 'react';
import './Index.css';
import Banner from "../Banner/Banner";
import HeadImage from "./HeadImage/HeadImage";
import Introduction from './Introduction/Introduction';
import Specialties from './Specialists/Specialties';
import OpenHours from "./OpenHours/OpenHours";
import Podiatrists from "./Podiatrists/Podiatrists";
import Footer from "../Footer/Footer";


function Index(props) {
    return (
        <div>
            <Banner/>
            <HeadImage/>
            <Introduction/>
            <Specialties/>
            <OpenHours />
            <Podiatrists />
            <Footer />

            {/*<a id="back-to-top" href="#"><i className="fa fa-angle-up"></i></a>*/}

            {/*<ul className="kiwi-floating-bar rect left ">*/}
            {/*    <li><a data-class="popup" data-network="facebook" className="kiwi-nw-facebook"*/}
            {/*           href="https://www.facebook.com/sharer.php?u=https%3A%2F%2Floveyourfeet.co.nz" target="_blank"*/}
            {/*           rel="nofollow"><span className="kicon-facebook"></span> </a><a data-class="popup"*/}
            {/*                                                                          className="kiwi-nw-facebook network-label"*/}
            {/*                                                                          data-network="facebook"*/}
            {/*                                                                          href="https://www.facebook.com/sharer.php?u=https%3A%2F%2Floveyourfeet.co.nz">Facebook</a>*/}
            {/*    </li>*/}
            {/*    <li><a data-class="popup" data-network="twitter" className="kiwi-nw-twitter"*/}
            {/*           href="https://twitter.com/intent/tweet?text=Auckland%20Podiatry%20Service&amp;url=https%3A%2F%2Floveyourfeet.co.nz"*/}
            {/*           target="_blank" rel="nofollow"><span className="kicon-twitter"></span> </a><a*/}
            {/*        data-class="popup"*/}
            {/*        className="kiwi-nw-twitter network-label"*/}
            {/*        data-network="twitter"*/}
            {/*        href="https://twitter.com/intent/tweet?text=Auckland%20Podiatry%20Service&amp;url=https%3A%2F%2Floveyourfeet.co.nz">Twitter</a>*/}
            {/*    </li>*/}
            {/*</ul>*/}
            {/*<link rel='stylesheet' id='kiwi_social_sharing-frontend-css'*/}
            {/*      href='wp-content/plugins/kiwi-social-share/assets/css/frontend.min6b25.css?ver=2.1.4'*/}
            {/*      type='text/css'*/}
            {/*      media='all'/>*/}
        </div>


    );
}

export default Index;