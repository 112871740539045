import React from 'react';
import './Specialties.css';
import Conditions from '../../../assets/Conditions.pdf';

function Specialties() {
    return (
        <div className="specialties-section">
            <div className="container-fluid my-5 pt-3 px-5">
                <div className="row">
                    <div className="col-md-4">
                        <div className="row icon-box mb-4 align-items-start">
                            <i className="fa fa-stethoscope icon-style col-2 d-flex justify-content-center"></i>
                            <div className="icon-box-content col-9 lh-lg">
                                <h6 className="fw-bold fs-5">Toenail and Toenail Conditions</h6>
                                <p className="text-secondary">General foot care, hard nail cutting, fungal nail
                                    treatment, and ingrown toenail management.</p>
                            </div>
                        </div>

                        <div className="row icon-box mb-4 align-items-start">
                            <i className="fa fa-cut icon-style col-2 d-flex justify-content-center"></i>
                            <div className="icon-box-content col-9 lh-lg">
                                <h6 className="fw-bold fs-5">Foot Skin Conditions</h6>
                                <p className="text-secondary">Treatment for fungal skin infections (verruca), corns,
                                    calluses, and cracked heels.</p>
                            </div>
                        </div>

                        <div className="row icon-box mb-4 align-items-start">
                            <i className="fa fa-exclamation icon-style col-2 d-flex justify-content-center"></i>
                            <div className="icon-box-content col-9 lh-lg">
                                <h6 className="fw-bold fs-5">Diabetic Foot Care</h6>
                                <p className="text-secondary ">Comprehensive assessment and treatment for diabetic foot
                                    conditions.</p>
                            </div>
                        </div>

                    </div>

                    <div className="col-md-4">


                        <div className="row icon-box mb-4 align-items-start">
                            <i className="fa fa-fire icon-style col-2 d-flex justify-content-center"></i>
                            <div className="icon-box-content col-9 lh-lg">
                                <h6 className="fw-bold fs-5">Pain Management</h6>
                                <p className="text-secondary ">Addressing foot, leg, and lower back pain caused by
                                    abnormal gait.</p>
                            </div>
                        </div>

                        <div className="row icon-box mb-4 align-items-start">
                            <i className="fa fa-check icon-style col-2 d-flex justify-content-center"></i>
                            <div className="icon-box-content col-9 lh-lg">
                                <h6 className="fw-bold fs-5">Gait Problems</h6>
                                <p className="text-secondary">Evaluation and treatment of gait issues resulting from
                                    ankle conditions or following knee and/or hip replacements.</p>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-4">
                        <h4 className="fs-3 text fw-bold">Main Specialties</h4>
                        <p><span className="text-accent-color font-weight-bold">We value our patients</span></p>
                        <p className="text-secondary">Podiatrists are in demand more than ever today due to a rapidly
                            growing and aging population.
                            <em><strong>To help you solve any foot issues you may have, feel free to contact
                                us.</strong></em></p>
                        <br/>
                        <a className="btn btn-warning rounded-pill text-white fs-3 fw-bold text px-4 py-3"
                           href={Conditions}>More information</a>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Specialties;
