import React, { createContext, useState, useContext } from 'react';

// 创建 Context
const AccessTokenContext = createContext();

// 创建 Provider
export const AccessTokenProvider = ({ children }) => {
    const [accessToken, setAccessToken] = useState('');

    return (
        <AccessTokenContext.Provider value={{ accessToken, setAccessToken }}>
            {children}
        </AccessTokenContext.Provider>
    );
};

// 创建 Hook
export const useAccessToken = () => {
    return useContext(AccessTokenContext);
};
