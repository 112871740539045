import React from 'react';
import logo from '../../assets/cropped-logo_LYFP-2.png';


function Banner() {
    return (
        <div className="sticky-top">
            <nav className="navbar  navbar-expand-lg bg-body">
                <div className="container-fluid">
                    <a className="navbar-brand ps-md-5 " href="/">
                        <img src={logo} alt="Logo" width="150" height="40"/>
                    </a>
                    {/* Navbar Toggler for smaller screens */}
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse text-end" id="navbarNav">
                        <ul className="navbar-nav ms-auto pe-3">
                            <li className="nav-item">
                                <a className="nav-link active" aria-current="page" href="/">Home</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="/appointment">Appointment</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="/contactus">Contact Us</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        </div>
    );
}

export default Banner;
