import React from 'react';
import './HeadImage.css';

function HeadImage(props) {
    return (
        <div className="background-image intro-section">
            <div className="container ">
                <div className="pt-5">
                    <br/>
                </div>
                <div className="pt-5 ">
                    <br/>
                </div>
                <div className="row mt-5 py-5">
                    <div className="col-sm-7">
                        <h1><strong>LOVE YOUR FEET Podiatry</strong></h1>
                        <p><span className="text-accent-color">Biomechanical assessment and treatment. Sport injuries. Diabetes, assessment and treatment</span>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default HeadImage;