import React from 'react';
import './OpenHours.css'

function OpenHours(props) {
    return (
        <div className="open-hours-section">
            <div className="container">
                <div className="row">
                    <div className="col-12 col-md-7">
                        <div className="open-hours-section-info text-white mt-5 pt-5">
                            <h1 className="text-center fw-bold">Top notch <br/> experience</h1>
                            <br/>
                            <p className="text-center text-white">Our specialist make sure you get the best care there is.</p>
                            <p className="text-center my-md-5">
                                <a className="btn btn-warning rounded-pill text-white fw-bold border-0 px-4 py-2"
                                   href="/appointment">Make an appointment
                                </a>
                            </p>
                        </div>

                    </div>
                    <div className=" col-12 col-md-5 ">
                        <div className="open-hours bg-warning  m-2 my-md-0">

                            <h4 className="text-white text-center fw-bolder border-1 border-bottom border-worning fs-2 py-4">Opening Hours</h4>

                            <h6 className="text-white text-center fw-bolder fs-5 pt-3">
                                <span className="text-uppercase">Tuesday</span>
                            </h6>
                            <p className="fw-bolder fs-4 text-center ">OREWA</p>
                            <h6 className="text-white text-center fw-bolder fs-5 mt-4">
                                <span className="text-uppercase">Wednesday </span>
                            </h6>
                            <p className="fw-bolder fs-4 text-center ">OREWA</p>
                            <h6 className="text-white text-center fw-bolder fs-5 mt-4">
                                <span className="text-uppercase">Thursday </span>
                            </h6>
                            <p className="fw-bolder fs-4 text-center ">BROWNS BAY </p>
                            <h6 className="text-white text-center fw-bolder fs-5 mt-4">
                                <span className="text-uppercase">Friday</span>
                            </h6>
                            <p className="fw-bolder fs-4 text-center ">MILFORD</p>

                            <br/>

                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
}

export default OpenHours;