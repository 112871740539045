import React from 'react';
import massage from "../../../assets/pexels-photo-356053.jpeg"
import "./Introduction.css"


function Introduction() {
    return (
        <div>
            <div className="introduction container-fluid mt-5  border-bottom border-3 border-warning">
                <div className="row mb-4 p-5">
                    <div className="col-md-6">
                        <h4 className="fw-bold">Welcome to Love Your Feet Podiatry</h4>

                        <div className=" text-body-secondary mt-3">

                            Vivian graduated with a Bachelor of Health Science in Podiatry from Auckland University of
                            Technology in 2006. Since 2007, she has worked in various private podiatry clinics.

                            <br/><br/>

                            In 2011, Vivian established her own podiatry service, "Love Your Feet Podiatry." She has
                            extensive experience treating a wide range of podiatry conditions, including toenail and
                            foot skin problems, sports injuries, and biomechanical conditions.

                            <br/><br/>
                            Following her clinical experience at the diabetic foot clinic (wound care) at Waitakere
                            Hospital, Vivian has assessed and treated a significant number of diabetes patients since
                            2012.
                            <br/><br/>
                            In 2022, she became a contracted provider for Veterans Affairs.

                            <br/><br/>
                            Vivian operates two podiatry clinics and serves three retirement villages. She is also a
                            trained medical doctor in China, with 12 years of experience practicing at a large hospital
                            overseas.
                        </div>
                    </div>

                    <div className=" col-md-5">
                        <img src={massage} alt="Welcome_image" className="img-fluid "/>
                    </div>
                </div>
            </div>

        </div>
    );
}

export default Introduction;