import React from 'react';
import Banner from "../Banner/Banner";
import Footer from "../Footer/Footer";
import GuideForContact from "./GuideForContact";
import ContactForm from "./ContactForm";

function Index(props) {
    return (
        <div>
            <Banner />
            <GuideForContact />
            <ContactForm />
            <Footer />
        </div>
    );
}

export default Index;