import React from 'react';
import './GuideForBooking.css'

function GuideForBooking(props) {
    return (
        <div>
            <div id="content">
                <div class="container-fluid py-5 bg-body-tertiary">
                    <div class="row py-5">
                        <div class="col-sm-11 ms-2 ms-md-5">
                            <h2 class="fs-1 fw-bold ">Appointment</h2>
                            <p>
                                <span class="text-accent-color ">Auckland Podiatry Service</span>
                            </p>
                            <p class="baseline "></p>
                        </div>

                    </div>
                </div>
            </div>

            <div class="container my-5 pt-sm-5">
                <div className="row d-flex justify-content-center">
                    <div className="col-sm-8">
                        <h3 className="text-left "><strong>
                            Guide for Online Bookings:
                        </strong></h3>
                        <div className="fs-6 fw-bold text-left mt-4 text-secondary lh-lg">
                            <p>
                                Step 1: Choose a day with <span className="green">Green</span> color.
                            </p>
                            <p>
                            Step 2: Choose the appointment type and get the available slots. if there is no
                                available slot, please choose another day.
                            </p>
                            <p>
                                Step 3: Enter your Name and Phone Number.
                            </p>
                            <p>Please note all required details must be filled in, if you do not have a mobile
                                please enter home number.
                            </p>
                            <p>Price:
                                <br/>
                                <i class="fa fa-check-square-o me-1" aria-hidden="true"></i>
                                Standard Foot Care (30 mins): $70
                                <br/>
                                <i class="fa fa-check-square-o me-1" aria-hidden="true"></i>
                                Abnormal Gait and Orthotics Treatment (60 mins): $140
                            </p>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
}

export default GuideForBooking;