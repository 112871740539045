import React, {useRef} from 'react';
import emailjs from '@emailjs/browser';

const ContactForm = () => {

    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs
            .sendForm(
                'service_n7bdp2g',
                'template_gnnlb8c',
                form.current, {
                    publicKey: 'sSI5Y2MoF7ZCfppiT',
                })
            .then(
                (result) => {
                    // 弹出成功消息
                    alert('Thanks for contacting us! We will be in touch with you shortly.');
                },
                (error) => {
                    // 控制台中打印错误信息
                    console.error('邮件发送失败:', error);
                }
            );
        e.target.reset()
    };


    return (
        <section className="contact section mt-5 mb-5" id="contact">
            <div className="ps-3 ps-md-5">
                <p>Send us an email and we will get back in touch with you as soon as we can.
                    <br/>
                    Alternatively if you’d like to book online please <a href="/appointment">click here</a>
                </p>
            </div>

            <div className=" ps-3 ps-md-5 me-3 me-md-5">
                <div className="contact__contentps-3">
                    <form ref={form} onSubmit={sendEmail} className="contact__form">
                        <div className="mb-3">
                            <label className="form-label">Name</label>
                            <br/>
                            <input type="text"
                                   name="name"
                                   placeholder="Insert your name"
                                   className="form-control"/>
                        </div>

                        <div className="mb-3">
                            <label className="form-label">Mail</label>
                            <br/>
                            <input type="email"
                                   name="email"
                                   placeholder="Insert your email"
                                   className="form-control"/>
                        </div>

                        <div className="mb-3">
                            <label className="form-label">Phone Number</label>
                            <br/>
                            <input name="phone"
                                   placeholder="Insert your phone number"
                                   className="form-control"/>
                        </div>


                        <div className="mb-3 contact__form-div contact__form-area">
                            <label className="form-label">Comment or Message</label>
                            <br/>
                            <textarea name="message"
                                      cols="30"
                                      rows="10"
                                      placeholder="Write your comment or message *"
                                      className="form-control">

                         </textarea>
                        </div>

                        <button href="#contact" type="submit" value="Send" class="btn btn-outline-secondary">
                            Send Message
                        </button>
                    </form>
                </div>

            </div>
        </section>
    );
};

export default ContactForm;