import React, {useState, useEffect} from "react";
import axios from "axios";
import {useAccessToken} from "./AccessTokenContext";
import "./AppointmentDetail.css"; // 导入 CSS 文件

const AppointmentDetail = ({show, onHide, selectedDate, onConfirm}) => {
    const {accessToken} = useAccessToken();
    const [appointmentType, setAppointmentType] = useState("");
    const [availableSlots, setAvailableSlots] = useState([]);
    const [loading, setLoading] = useState(false);
    const [selectedSlot, setSelectedSlot] = useState("");
    const [location, setLocation] = useState("");
    const [name, setName] = useState("");
    const [phone, setPhone] = useState("");
    // const [email, setEmail] = useState("");
    const [successMessage, setSuccessMessage] = useState(""); // 用于存储成功提示信息


    // 使用 useEffect，根据 selectedDate 自动设置地点
    useEffect(() => {
        if (selectedDate) {
            const date = new Date(selectedDate);
            const dayOfWeek = date.getDay();
            if (dayOfWeek === 4) { // 星期四
                setLocation("6A Branston Avenue, Browns Bay, Auckland");
            } else if (dayOfWeek === 5) { // 星期五
                setLocation("145 Kitchener Road, Milford, Auckland");
            } else {
                setLocation("");
            }
        }
    }, [selectedDate]);

    if (!show) return null;

    const appointmentTypes =
        location === "6A Branston Avenue, Browns Bay, Auckland"
            ? {
                "Standard Foot Care (30 mins)": 45,
                "Abnormal Gait and Orthotics Treatment (60 mins)": 75,
            }
            : {
                "Standard Foot Care (30 mins)": 30,
                "Abnormal Gait and Orthotics Treatment (60 mins)": 60,
            };

    const generateTimeSlots = (duration, busyTimes = []) => {
        const slots = [];
        const start = new Date(`${selectedDate}T09:30:00`);
        const end = new Date(`${selectedDate}T17:00:00`);

        let current = new Date(start);

        // 确保 busyTimes 是有效的数组
        busyTimes = busyTimes.filter((busy) => busy && busy.start && busy.end);

        // 按开始时间排序忙碌时间段
        busyTimes.sort((a, b) => new Date(a.start) - new Date(b.start));

        while (current < end) {
            const slotStart = new Date(current);
            const slotEnd = new Date(slotStart);
            slotEnd.setMinutes(slotStart.getMinutes() + duration);

            // 确保时间段不超出工作时间
            if (slotEnd > end) break;

            // 检查时间段是否与忙碌时间重叠
            let isBusy = false;
            let overlappingBusyEnd = null;

            for (let busy of busyTimes) {
                const busyStart = new Date(busy.start);
                const busyEnd = new Date(busy.end);

                // 检查重叠
                if (busyStart < slotEnd && busyEnd > slotStart) {
                    isBusy = true;
                    if (!overlappingBusyEnd || busyEnd > overlappingBusyEnd) {
                        overlappingBusyEnd = busyEnd;
                    }
                }
            }

            if (!isBusy) {
                slots.push({
                    start: slotStart.toISOString(),
                    end: slotEnd.toISOString(),
                });
                current = slotEnd;
            } else {
                // 将 current 移动到忙碌时间的结束
                current = overlappingBusyEnd;
            }
        }

       return slots;
    };

    const fetchFreeBusy = async (duration) => {
        setLoading(true);
        try {
            const start = new Date(`${selectedDate}T09:30:00`);
            const end = new Date(`${selectedDate}T17:00:00`);

            const requestBody = {
                timeMin: start.toISOString(),
                timeMax: end.toISOString(),
                timeZone: "Pacific/Auckland",
                items: [{id: "primary"}],
            };

            const response = await axios.post(
                "https://www.googleapis.com/calendar/v3/freeBusy",
                requestBody,
                {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                        "Content-Type": "application/json",
                    },
                }
            );

            if (
                response.data &&
                response.data.calendars &&
                response.data.calendars.primary &&
                response.data.calendars.primary.busy
            ) {
                let busyTimes = response.data.calendars.primary.busy;

                // 确保 busyTimes 是有效的数组
                busyTimes = busyTimes.filter((busy) => busy && busy.start && busy.end);

                // 生成可用时间段
                const adjustedSlots = generateTimeSlots(duration, busyTimes);
                setAvailableSlots(adjustedSlots);
            } else {
                console.error("Unexpected API response format:", response.data);
                setAvailableSlots([]); // 无可用时间段
            }
        } catch (error) {
            if (error.response) {
                console.error("API Error:", error.response.data);
            } else if (error.request) {
                console.error("No response received:", error.request);
            } else {
                console.error("Error in request setup:", error.message);
            }
        } finally {
            setLoading(false);
        }
    };

    const handleTypeChange = (type) => {
        setAppointmentType(type);
        if (type) {
            fetchFreeBusy(appointmentTypes[type]);
        } else {
            setAvailableSlots([]);
        }
    };

    const handleEventAdd = async (event) => {
        try {
            const [startTimeISO, endTimeISO] = event.time.split(" to ");
            const startDateTime = new Date(startTimeISO);
            const endDateTime = new Date(endTimeISO);

            // 验证日期时间是否有效
            if (isNaN(startDateTime.getTime()) || isNaN(endDateTime.getTime())) {
                throw new Error("Invalid start or end time!");
            }

            // 构建 Google Calendar API 的事件负载
            const newEvent = {
                summary: `Appointment: ${event.name}`,
                location: event.location,
                description: `Phone: ${event.phone}, Email: ${event.email}`,
                start: {
                    dateTime: startDateTime.toISOString(),
                    timeZone: "Pacific/Auckland",
                },
                end: {
                    dateTime: endDateTime.toISOString(),
                    timeZone: "Pacific/Auckland",
                },
                reminders: {
                    useDefault: false,
                    overrides: [
                        {method: "email", minutes: 1440},
                        {method: "popup", minutes: 10},
                    ],
                },
            };

            // 调用 Google Calendar API 创建事件
            const response = await axios.post(
                "https://www.googleapis.com/calendar/v3/calendars/primary/events",
                newEvent,
                {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                        "Content-Type": "application/json",
                    },
                }
            );

            // 显示成功消息
            setSuccessMessage(
                "If you need to change the time or cancel, please call us."
            );

            // 可选：通知父组件
        } catch (error) {
            console.error("Error creating event:", error.message || error);
        }
    };

    const handleSubmit = () => {
        if (
            !appointmentType ||
            !selectedSlot ||
            !location ||
            !name ||
            !phone
        ) {
            alert("Please fill out all fields!");
            return;
        }

        const eventDetails = {
            type: appointmentType,
            date: selectedDate,
            time: selectedSlot,
            location, // 确保地点信息被传递
            name,
            phone,
            // email,
        };

        handleEventAdd(eventDetails);
        // onHide(); // 移除此行，以便在显示成功消息后再关闭弹窗
    };

    const handleClose = () => {
        setSuccessMessage(""); // 清除成功消息
        onHide(); // 关闭弹窗

    };

    return (
        <div className="modal-overlay">
            <div className="modal-content">
                {successMessage ? (
                    // 成功消息界面
                    <div>
                        <h3>Appointment Successful!</h3>
                        <p>{successMessage}</p>
                        <button onClick={handleClose} className="confirm-button">
                            Confirm
                        </button>
                    </div>
                ) : (
                    // 预约表单界面
                    <div>
                        <h2>Appointment Details</h2>
                        <p>
                            <strong>Selected Date:</strong> {selectedDate}
                        </p>
                        <p>
                            <strong>Location:</strong> {location}
                        </p>

                        <div className="form-group">
                            <label>Appointment Type:</label>
                            <select
                                value={appointmentType}
                                onChange={(e) => handleTypeChange(e.target.value)}
                                className="full-width-input"
                            >
                                <option value="">Select Type</option>
                                {Object.keys(appointmentTypes).map((type) => (
                                    <option key={type} value={type}>
                                        {type}
                                    </option>
                                ))}
                            </select>
                        </div>

                        <div className="form-group">
                            <label>Time Start At:</label>
                            {loading ? (
                                <p>Loading available time slots...</p>
                            ) : (
                                <select
                                    value={selectedSlot}
                                    onChange={(e) => setSelectedSlot(e.target.value)}
                                    className="full-width-input"
                                >
                                    <option value="">Select Start Time</option>
                                    {availableSlots.map((slot, index) => (
                                        <option key={index} value={`${slot.start} to ${slot.end}`}>
                                            {new Date(slot.start).toLocaleTimeString("en-NZ", {
                                                hour: "2-digit",
                                                minute: "2-digit",
                                                timeZone: "Pacific/Auckland",
                                            })}
                                            {/*-*/}
                                            {/*{new Date(slot.end).toLocaleTimeString("en-NZ", {*/}
                                            {/*    hour: "2-digit",*/}
                                            {/*    minute: "2-digit",*/}
                                            {/*    timeZone: "Pacific/Auckland",*/}
                                            {/*})}*/}
                                        </option>
                                    ))}
                                </select>
                            )}
                        </div>

                        {/* 移除了地点的输入框 */}


                        <div className="form-group">
                            <label>Name:</label>
                            <input
                                type="text"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                placeholder="Enter your name"
                                className="full-width-input"
                            />
                        </div>

                        <div className="form-group">
                            <label>Phone:</label>
                            <input
                                type="text"
                                value={phone}
                                onChange={(e) => setPhone(e.target.value)}
                                placeholder="Enter your phone number"
                                className="full-width-input"
                            />
                        </div>

                        {/*<div className="form-group">*/}
                        {/*  <label>Email:</label>*/}
                        {/*  <input*/}
                        {/*    type="email"*/}
                        {/*    value={email}*/}
                        {/*    onChange={(e) => setEmail(e.target.value)}*/}
                        {/*    placeholder="Enter your email"*/}
                        {/*    className="full-width-input"*/}
                        {/*  />*/}
                        {/*</div>*/}

                        <div className="button-group">
                            <button onClick={onHide} onTouchStart={onHide} className="cancel-button">
                                Cancel
                            </button>
                            <button onClick={handleSubmit} className="confirm-button">
                                Confirm Appointment
                            </button>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default AppointmentDetail;