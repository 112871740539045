import React from 'react';
import './Footer.css';
import ProCare from '../../assets/ProCare.png';

function Footer(props) {
    return (
        <div>
            <div className="container-fluid contactus mt-4 pb-5 text-white">
                <div className="row align-items-center">
                    <div className="col-12 col-md-7">
                        <div className="px-3 px-md-5">
                            <h2 className="fw-bold py-5">
                                CONTACT US NOW!
                            </h2>
                            <div className="text-white-50 fs-5 lh-lg">
                                <p><strong>Phone: 09 478 8735<br/>
                                    Mobile: 021 160 3227<br/>
                                    Email: </strong>
                                    <a href="mailto:loveyourfeet@ymail.com">
                                        <strong>loveyourfeet@ymail.com</strong></a>
                                </p>
                            </div>
                        </div>
                    </div>


                    <div className="col-12 col-md-5">
                        <div className="px-3 px-md-0 text-start">
                            <div>
                                <h2 className="fw-bold pt-5 mb-2">
                                    LOCATION
                                </h2>
                                <p className="text-white-50 fs-5 lh-lg mt-4">
                                    Browns Bay Clinic <br/>
                                    Milford Clinic, 145 Kitchener Road</p>
                                <div className="picture-inline">
                                    <p><img
                                            className="alignnone wp-image-89 size-full"
                                            src={ProCare} alt="" width="250"
                                            // srcSet="https://loveyourfeet.co.nz/wp-content/uploads/2017/12/ProCare.png 1159w, https://loveyourfeet.co.nz/wp-content/uploads/2017/12/ProCare-300x57.png 300w, https://loveyourfeet.co.nz/wp-content/uploads/2017/12/ProCare-768x147.png 768w, https://loveyourfeet.co.nz/wp-content/uploads/2017/12/ProCare-1024x196.png 1024w"
                                            sizes="(max-width: 768px) 100vw, 1159px"/></p>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                {/*// <!--.row-->*/}
            </div>
            <div className="allright container-fluid py-2">
                <div className="row align-items-center">
                    <div className="col-sm-6 text-white">
                        © 2024 loveyourfeet.co.nz All rights reserved.
                    </div>

                    <div className="col-sm-6">
                        <div className="text-start text-sm-end pe-1">
                            <a href="mailto:ellyjj1@hotmail.com">Website made by Ellie Yu</a>
                        </div>
                    </div>
                    {/*// <!-- end .col -->*/}
                </div>
                {/*// <!-- end .row -->*/}
            </div>

        </div>
    );
}

export default Footer;