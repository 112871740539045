import React from 'react';
import './Podiatrists.css'
import vivian_wang from '../../../assets/vivianwang-1.jpg'

function Podiatrists() {
    return (
        <div>
            <div className="container mt-5">
                <div className="row">
                    <div className="col-sm-12">
                        <h4 className="text-center fw-bold">Meet our Podiatrists</h4>

                        <p className="text-center mt-3 text-secondary">
                            Our experts are here for you every single day! We care
                            about our patients and we
                            <br/>
                            do our best to make them happy.
                        </p>
                        <br/>
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="row">


                    <div className="">
                        <div className="doctor-profile">
                            <div>
                                <img className="rounded img-fluid mx-auto d-block" src={vivian_wang} alt=""/>
                            </div>
                            <div className="mt-4 text-center">
                                <h6 className=" fw-bold">Vivian Wang</h6>
                                <p className="text-accent-color">Podiatrists</p>
                                <p className="text-secondary">Outside of work she enjoys arts, <br/>gym
                                    and outdoor activities
                                </p>
                            </div>

                            {/*<div className="social-links">*/}
                            {/*    <a className="facebook-icon social-icon"*/}
                            {/*       href="https://www.facebook.com/Loveyourfeet-Podiatry-Auckland-1408543995834909/">*/}
                            {/*        <i className="fa fa-facebook"></i> </a>*/}
                            {/*    <a className="twitter-icon social-icon" href="https://twitter.com/">*/}
                            {/*        <i className="fa fa-twitter"></i> </a>*/}
                            {/*    <a className="google-plus-icon social-icon" href="https://google.com/">*/}
                            {/*        <i className="fa fa-google-plus"></i> </a>*/}
                            {/*    <a className="linkedin-icon social-icon" href="https://linkedin.com/">*/}
                            {/*        <i className="fa fa-linkedin"></i> </a>*/}
                            {/*</div>*/}
                        </div>
                    </div>

                </div>
            </div>
        </div>
    );
}

export default Podiatrists;