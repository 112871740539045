import React, { useEffect } from 'react';
import axios from 'axios';
import qs from 'qs';
import { useAccessToken } from './AccessTokenContext'; //引入一个全局变量
const TokenRequest = () => {
    const { setAccessToken } = useAccessToken(); // 使用 全局变量

    const clientId = process.env.REACT_APP_CLIENT_ID_VIVIAN;
    const clientSecret = process.env.REACT_APP_CLIENT_SECRET_VIVIAN;
    const refreshToken = process.env.REACT_APP_REFRESH_TOKEN_VIVIAN;

    useEffect(() => {
        const fetchAccessToken = async () => {
            const data = qs.stringify({
                client_id: clientId,
                client_secret: clientSecret,
                grant_type: 'refresh_token',
                refresh_token: refreshToken,
            });

            const config = {
                method: 'post',
                url: 'https://oauth2.googleapis.com/token',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                data: data,
            };

            try {
                const response = await axios.request(config);
                setAccessToken(response.data.access_token); // 保存 Access Token 到全局变量
                // console.log('Access Token:', response.data.access_token);
            } catch (error) {
                console.error('Error fetching access token:', error);
            }
        };

        fetchAccessToken();
    }, [clientId, clientSecret, refreshToken, setAccessToken]);

    return (
        <div>
            {/*<h1>Google OAuth Token Request</h1>*/}
            {/*<p>REACT_APP_CLIENT_ID_VIVIAN: {clientId}></p>*/}
            {/*<p>REACT_APP_CLIENT_SECRET_VIVIAN: {clientSecret}</p>*/}
            {/*    <p>REACT_APP_REFRESH_TOKEN_VIVIAN: {refreshToken}</p>*/}
            {/*{accessToken ? (*/}
            {/*    <p>Access Token: {accessToken}</p>*/}
            {/*) : (*/}
            {/*    <p>正在获取 Access Token...</p>*/}
            {/*)}*/}
        </div>
    );
};

export default TokenRequest;
